<template>
  <div>
    <q-form ref="editForm">
      <c-card title="순회점검 기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-text
              required
              :editable="editable"
              :disabled="disabled"
              label="순회명"
              name="patrolName"
              v-model="patrol.patrolName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              required
              :editable="editable"
              :disabled="disabled"
              label="순회일"
              type="date"
              name="patrolDate"
              v-model="patrol.patrolDate"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :editable="editable"
              :disabled="disabled"
              label="순회장소"
              name="sopMapId"
              v-model="patrol.sopMapId"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              required
              :disabled="disabled"
              :editable="editable" 
              type="edit" 
              name="plantCd" 
              v-model="patrol.plantCd"
              @datachange="datachange" />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-dept
              :editable="editable"
              :disabled="disabled"
              label="부서"
              name="vendorCd"
              v-model="patrol.vendorCd"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-process
              :editable="editable"
              :disabled="disabled"
              label="공정"
              name="processCd"
              v-model="patrol.processCd"
            >
            </c-process>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-construnction
              :editable="editable"
              :disabled="disabled"
              name="sopConstructionId"
              v-model="patrol.sopConstructionId">
            </c-construnction>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <c-risk-assess
              :editable="editable"
              :disabled="disabled"
              name="ramRiskAssessmentPlanId"
              v-model="patrol.ramRiskAssessmentPlanId">
            </c-risk-assess>
          </div>
        </template>
      </c-card>
      <c-table
        ref="grid"
        title="항목별 결과 목록"
        :isTitle="true"
        :columns="grid.columns"
        :gridHeightAuto="true"
        :data="patrol.results"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="항목별 결과 추가하세요."
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="saiPatrolResultId"
        selection="multiple"
      >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="saiPatrolResultId"
            ibmTaskTypeCd="ITT0000065"
            ibmTaskUnderTypeCd="ITTU000105"
            :acceptBtnShow="true"
            @imprChange="imprChange"
          >
            <template v-slot:customArea="{ props }">
              <div class="col-6">
                <div class="mini-dailog-title">{{$langauge('비고')}}</div>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable&&!disabled"
                  label=""
                  name="patrolContents"
                  v-model="props.row.patrolContents">
                </c-text>
              </div>
            </template>
          </component>
        </template>
      </template>
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn
              v-if="editable && !disabled"
              :showLoading="false"
              label="추가"
              icon="add"
              @btnClicked="addResult"
            />
            <c-btn
              v-if="editable && !disabled && patrol.results.length > 0"
              :showLoading="false"
              label="제외"
              icon="remove"
              @btnClicked="removeResult"
            />
          </q-btn-group>
        </template>
      </c-table>
      <c-card title="순회결과 정보" class="cardClassDetailForm" :collapsed="true">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-select
              codeGroupCd="PATROL_RESULT_CD"
              :editable="editable"
              type="edit"
              :disabled="disabled"
              itemText="codeName"
              itemValue="code"
              name="patrolResultCd"
              label="순회결과"
              v-model="patrol.patrolResultCd">
            </c-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="5"
              label="순회결과요약"
              name="patrolResultSummary"
              v-model="patrol.patrolResultSummary">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-upload 
        label="관련파일"
        :attachInfo="attachInfo"
        :editable="editable&&!disabled">
      </c-upload>
    </q-form>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-appr-btn 
            v-if="!popupParam.isApprContent"
            flat
            ref="appr-btn"
            name="work-permit-appr-btn"
            :editable="editable"
            :approvalInfo="approvalInfo"
            @beforeApprAction="approvalPatrol"
            @callbackApprAction="approvalPatrolCallback"
            @requestAfterAction="getDetail"
          />
          <c-btn
            v-show="editable&&!disabled"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="patrol"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="savePlan"
            @btnCallback="saveCallback" 
          />
          <c-btn 
            v-if="isOld && !disabled && editable && !popupParam.isApprContent" 
            flat
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="remove" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrolInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        isApprContent: false,
      }),
    },
    patrol: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        sopMapId: '',
        patrolTypeCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultCd: null,
        patrolResultSummary: '',
        patrolCompleteFlag: 'N',
        sopConstructionId: '',
        ramRiskAssessmentPlanId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
        patrolImproveModelList: [],
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'PATROL_RESULT',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'patrolItemName',
            field: 'patrolItemName',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            style: 'width:70%',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolResultCd',
            field: 'patrolResultCd',
            label: '점검결과',
            align: 'center',
            style: 'width:20%',
            type: 'select',
            codeGroupCd: 'PATROL_RESULT_CHECK_CD',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            type: 'custom',
            sortable: false
          },
        ],
        height: "600px",
        data: [],
      },
      editable: true,
      saveType: 'POST',
      resultItemUrl: '',
      saveUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      isSave: false,
      isApproval: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['patrolItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.patrol.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.patrol.approvalStatusCd, 
        apprEditable: this.editable
          && this.isOld 
          && !this.disabled, // 결재버튼 활성화 기준
        param: this.patrol, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000019', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiPatrolId: this.patrol.saiPatrolId,
          isApprContent: true
        },
        approvalRequestName: this.patrol.patrolName, // 결재요청명 (문서 title)
        approvalConnId: this.patrol.saiPatrolId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
    'patrol'() {
      this.$emit('transInfo', this.patrol);
    },
    // 'count'() {
    //   this.getDetail();
    // }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.resultItemUrl = selectConfig.sai.patrol.resultItem.list.url;
      this.saveUrl = transactionConfig.sai.patrol.insert.url;
      this.deleteUrl = transactionConfig.sai.patrol.delete.url;
      this.approvalUrl = transactionConfig.sai.patrol.update.url;
      // code setting
      // list setting
    },
    getDetail() {
      this.$emit('getDetail')
    },
    addResult() {
      this.patrol.results.splice(0, 0, {
        saiPatrolId: this.popupParam.saiPatrolId,
        saiPatrolResultId: uid(),
        patrolItemName: '',
        patrolResultCd: 'PRCC000001',
        patrolContents: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", 
          message: "선택된 항목이 없습니다.",
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deleteResults) this.patrol.deleteResults = [];
          if (this.$_.findIndex(this.patrol.deleteResults, {
                saiPatrolResultId: item.saiPatrolResultId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.patrol.deleteResults.push(item);
          }
          this.patrol.results = this.$_.reject(this.patrol.results, item);
        });
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.patrol.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.patrol.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.patrol.regUserId = this.$store.getters.user.userId
              this.patrol.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiPatrolId', _result.data)
        this.$set(this.attachInfo, 'taskKey', _result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$emit('getDetail');
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiPatrolId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange(data) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '점검항목이 변경됩니다. 진행하시겠습니까?', 
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.patrol.results = [];
          if(!data.value) {
            return;
          } else {
            this.$http.url = this.resultItemUrl;
            this.$http.type = 'GET';
            this.$http.param = {
              plantCd: data.value,
              useFlag: 'Y',
            };
            this.$http.request((_result) => {
              this.$_.forEach(_result.data, _item => {
                this.patrol.results.push({
                  saiPatrolId: this.popupParam.saiPatrolId,
                  saiPatrolResultId: uid(),
                  patrolItemName: _item.patrolItemName,
                  patrolResultCd: 'PRCC000001',
                  patrolContents: '',
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C',
                }) 
              })
            },);
          }
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalPatrol() {
      /**
       * 유효성 체크
       *  1. 점검결과가 하나라도 입력되었는지?
       */
      let isIng = true;
      if (!this.patrol.results || this.patrol.results.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '점검결과를 하나 이상 입력하세요.', 
          type: 'warning', // success / info / warning / error
        });
        isIng = false;
      } 

      if (isIng) {
        this.$refs['editForm'].validate().then(_result => {
          if (_result) {
            window.getApp.$emit('CONFIRM', {
              title: '안내',
              message: '결재요청 하기 전 입력된 값을 저장합니다. 진행하시겠습니까?',
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.patrol.regUserId = this.$store.getters.user.userId
                this.patrol.chgUserId = this.$store.getters.user.userId
                
                this.isApproval = !this.isApproval
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            window.getApp.$emit('APP_VALID_ERROR');
          }
        });
      }
    },
    // [S] 결재관련 
    approvalPatrolCallback(result) { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
